import React from "react";

const PopupP2 = (props2) => {
  return props2.trigger ? (
    <div className="popup">
      <div className="popup_inner">
        <button
          className="close_btn btn btn-primary"
          onClick={() => props2.setTrigger(false)}
        >
          Close
        </button>
        <div>
          <span className="scroll_item">Scroll Down</span>
        </div>
        <h5>All About</h5>
        <h2>Travel Wright</h2>

        <h3>Project Origin</h3>
        <p>
          This is my coding project that I made while enrolled in a Udemy course
          by Jonas Schmedtmann. It is a basic CRUD app, which requires using
          React and dabbling with useState, lifting it, passing down it's props,
          and much more.
        </p>
        <h3>How This Project Works</h3>
        <p>
          Ok so, it is a very simple project with a simple UI. It has different
          components with different functionality, such as the title
          &#40;static&#41;, a form element &#40;to input the items&#41;, the
          itmes list &#40;where you can check them off as completed, or delete
          them&#41;, a sort by input and, a conditionally rendered statement
          &#40;which displays a start statement, percentage packed, and if
          completed&#41;
        </p>
        <br />
        <br />
        <p>The components are broken down into different files:</p>
        <ul>
          <li>
            <span className="popup_li_span">1:</span>App
          </li>
          <li>
            <span className="popup_li_span">2:</span> Logo
          </li>
          <li>
            <span className="popup_li_span">3:</span> Form
          </li>
          <li>
            <span className="popup_li_span">4:</span> Item
          </li>
          <li>
            <span className="popup_li_span">5:</span> Packing List
          </li>
          <li>
            <span className="popup_li_span">6:</span> Stats
          </li>
        </ul>
        <br />
        <p>
          This project had a major focus on state. React is all about useState,
          where it lives, where it need to be to be passed down to sibling
          componenets, how it is accessed, and what it does.
        </p>
        <br />
        <br />
        <p>
          Once the static app was built I had to go through to create the
          functionality/state where it was needed. Once the different components
          were operational, some of the state needed to be accessed by sibling
          components, thus it was lifted to the nearest parent component.
        </p>
        <h3>The Challenges I faced with this project</h3>
        <p>
          Understanding where state need to be to be accessed can be a
          challenge. If I did not take my time building it piece by piece, then
          state can become lost, tangled, or just plain old break/not work if I
          did not pass it down or lift it correctly
        </p>
        {props2.children}
      </div>
    </div>
  ) : (
    ""
  );
};

export default PopupP2;
