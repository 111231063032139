import React from "react";

const PopupP1 = (props1) => {
  return props1.trigger ? (
    <div className="popup">
      <div className="popup_inner">
        <button
          className="close_btn btn btn-primary"
          onClick={() => props1.setTrigger(false)}
        >
          Close
        </button>

        <div>
          <span className="scroll_item">Scroll Down</span>
        </div>

        <h5>All About</h5>
        <h2>Travel Wright</h2>

        <h3>Project Origin</h3>
        <p>
          This is my coding project that I made while enrolled in a Udemy course
          by Jonas Schmedtmann. It is a basic CRUD app, which requires using
          React and dabbling with useState, lifting it, passing down it's props,
          and much more.
        </p>
        <h3>How This Project Works</h3>
        <p>
          Ok so, it is a very simple project with a simple UI. It has different
          components with different functionality, such as the title
          &#40;static&#41;, a form element &#40;to input the items&#41;, the
          itmes list &#40;where you can check them off as completed, or delete
          them&#41;, a sort by input and, a conditionally rendered statement
          &#40;which displays a start statement, percentage packed, and if
          completed&#41;
        </p>
        <h3>The Challenges I faced with this project</h3>
        <p>
          The project contained simple functions involving Math.floor and
          Math.random, to obtain numbers that were later used as the inputs for
          the rgb values. I also utilized some basic if-else functions and event
          listeners. These were the easy parts. The hardest part was when I
          tried to change the text color, based off of the color, rgb value,
          returned to me. At first the value for the rgb was immediately
          returned as a string, but I later returned it as an object, which was
          easier to manipulate, and extract for the rgb and hex functions.
          <br />
          <br />
          In order to change the color of the text based off of the darkness of
          the rgb, I had to first understand how my function returned values.
          Meaning were they in an array? object? string? integer? Well, I found
          out that my function was returning an object with number as strings.
          Once I understood this, I was able to pass through another function
          which converted the object to an array, and parseInt those items to
          make them integers. At this point, I then was able to loop over the
          array to find the total. The total was then compared a hardcoded
          "threshold" value which was used as the parameter for my if-else
          statement, to determine when and if the text color was changed
          <br />
          <br />
          Once I understood that how a function was returning information to me,
          I was able to adjust accordingly! I learned a lot coding this project,
          as it forced me to be organized, use critical thinking, and a lot of
          problem solving/debugging.
        </p>

        {props1.children}
      </div>
    </div>
  ) : (
    ""
  );
};

export default PopupP1;
